@import '../../styles/mixins';

  .room-types-image {
    border-radius:15px
  }

  .room-types-column {
    text-align: start;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: center!important;
    button {
      height: 32px;
      max-height: 32px;
    }
  }
