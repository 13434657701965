@import '../../styles/mixins';


.notif {
  border-top: 1px #e7e7e7 solid!important;
  padding: 5px!important;

}

.notifs {
  box-shadow: 0px 6px 8px rgba(0,0,0,.4)!important;
}

