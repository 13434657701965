@import '../../styles/mixins';

.label {
  font-size:14px;
  text-align: center;
  font-weight: bold;
}

.home-image-loader {
  text-align:center;
  height: 100px;
  &.fill {
    height: 100%;
  }
  .image
  {
    max-height: 100px;
  }
  margin:10px;
}

.drop-text {
  font-size:16px;
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  //width: 100%;
  border: 2px dashed #0087F7;
  border-radius: 5px;
  .drop-text {
    font-size:16px;
  }
  &.blackbg {
    background-color: #212121;
    .drop-text {
      color:white;
    }
  }
  &:hover {
    background: aliceblue;
    cursor: pointer;
  }
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #baffad;
  box-shadow: 0 0 13px 3px #60ff40;
}

.file-input
{
  display:none;
}

.ReactCrop__image {
    max-height: 700px;
}

.croppingLayer {
  position: absolute;
  z-index: 1001;
  opacity: 0;
  top:-800px;
  background:#FFFFFF;
  border:1px solid #000000;

  &.show {
    opacity:1; 
    top:0;
  }
}